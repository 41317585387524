import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { TextField, Autocomplete } from "@mui/material";

import { useFormContext } from "../../hooks/useFormContext";

import { getEntities } from "../../dao/common";
import { ejectDeletedEntities, getIdValues } from "./../../utils/utils";

function getNameFromValue(options, value, setLocationEntity) {
  if (value === "") return "";
  const location = options?.find((o) => o.id === value);

  if (location === undefined) {
    setLocationEntity(null);
    return "";
  }

  setLocationEntity(location);

  return `${location.number} ${location.description ?? ""}`;
}

function setLocationValue(option, value, locationId) {
  if (Number(value) <= 0) {
    return option === Number(value);
  }
  if (locationId) {
    return option === locationId;
  }
}

export default function LocationNumbersLookup({
  className,
  formStateHandlers,
  fieldSetting,
  helperText,
  variant,
  required,
  inputProps,
  ...props
}) {
  const { objectId, locationId, setLocationEntity } = useFormContext();

  const [data, setData] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    if (Number(objectId) !== -1 && typeof objectId === "string") {
      getEntities(
        "/locations",
        {
          object_ids: objectId,
          order: "number asc",
        },
        abortController
      ).then((res) => {
        setData(res.response);
      });
      return () => {
        abortController.abort();
      };
    }
  }, [objectId, locationId]);

  const { raw } = formStateHandlers;
  const fieldConfig = raw({
    ...fieldSetting,
    onChange: (input) => {
      const value = input.selectValue;
      fieldSetting.onChange({
        target: {
          value,
        },
      });
      return value;
    },
    validate: (value, _values, event) => {
      // Delibirately checking between event.selectValue and value
      // because when using tab to select an item,
      // 'value' might return undefined.
      return !required || !!event.selectValue || !!value;
    },
  });

  const cleanedLocations = ejectDeletedEntities(data);

  const fieldProps = {
    ...fieldConfig,
    ...props,
    onChange: (e, value) => {
      // Re-package value, useFormState does not pass through second arg
      e.selectValue = value;
      return fieldConfig.onChange(e);
    },
    id: `lookup_${fieldConfig.name}`,
    className: clsx(className),
  };

  const finalInputProps = {
    label: props.label,
    error: props.error,
    variant,
    required,
    helperText,
    ...inputProps,
  };

  return (
    <Autocomplete
      {...fieldProps}
      options={getIdValues(cleanedLocations)}
      getOptionLabel={(value) =>
        getNameFromValue(cleanedLocations, value, setLocationEntity)
      }
      isOptionEqualToValue={(option, value) =>
        setLocationValue(option, value, locationId)
      }
      autoSelect={true}
      renderInput={(params) => (
        <TextField
          {...params}
          {...finalInputProps}
          fullWidth
          InputLabelProps={{ shrink: true }}
          error={props.data?.isFieldFailed}
        />
      )}
    />
  );
}
