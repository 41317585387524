import { menuItems } from "../constants/drawerMenu";
import {
  ADMIN_ROLE,
  CONDITIONS_DISABLED_FOR_BOTH,
  CONDITIONS_ENGINEER,
  CONDITIONS_INSTALLATION,
  ENGINEER_ROLE,
  INSTALLATION_ROLE,
} from "../constants/locks";
import { FILTER_TYPES } from "../dao/types";
import moment from "moment";

function calculateSortorder(fieldA, fieldB) {
  return fieldA.sortOrder.overview - fieldB.sortOrder.overview;
}

export function sortColumns(columns) {
  return columns.sort(calculateSortorder);
}

/**
 * Function to export data to a CSV file and let the user
 * download it directly
 * @param {string} fileName
 * @param {mixed} data
 */
export function exportToFile(fileName, data, mimeType = "text/csv") {
  const blob = new Blob([data], { type: mimeType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
}

/**
 * Function to get the sort order based on the entity
 * @param {string} entity
 */
export const getSortOrder = (entity) => {
  switch (entity) {
    case FILTER_TYPES.project:
      return [{ field: "number", sort: "asc" }];
    case FILTER_TYPES.system_group:
    case FILTER_TYPES.location:
      return [
        { field: "object_number", sort: "asc" },
        { field: "number", sort: "asc" },
      ];
    case FILTER_TYPES.system:
      return [
        { field: "object_number", sort: "asc" },
        { field: "system_group_number", sort: "asc" },
        { field: "number", sort: "asc" },
      ];
    default:
      return [{ field: "number", sort: "asc" }];
  }
};

export const getEntityReference = (entity) => {
  if (typeof entity.project_type !== "undefined") {
    return `${entity.project_type}${entity.number}`;
  } else if (typeof entity.reference2 !== "undefined") {
    return entity.reference2;
  }
  // For a cable type, there is no number but a type
  return entity?.number ?? entity?.type;
};
/*
 * Non Operation functions wrapped in an object.
 * Can be used as a default values
 * @returns {undefined}
 */
export const noop = {
  fn: () => {
    return;
  },
  resolve: () => Promise.resolve("Noop Resolve"),
  reject: () => Promise.reject(new Error("Noop Resolve")),
};

/**
 * Function to remove all soft-deleted entities from an array
 * @param {object} entities
 */
export const ejectDeletedEntities = (entities = []) => {
  if (Array.isArray(entities)) {
    return entities.filter((entity) => typeof entity.deleted === "undefined");
  }
  return [];
};

/**
 * Function to return only the ID of the options
 * @param {array} options
 */
export const getIdValues = (options) => options?.map((option) => option.id);

/**
 * getInitials - Get the initials of a user name.
 * @param {string} username - The username of the user.
 * @returns {string} The initials of the user.
 */
export const getInitials = (username) => {
  // Return the parts of user.name which starts (\b) with an uppercase char followed by lowercase chars.
  const nameParts = username.match(/(\b[A-Z][a-z]+)/g);
  // Keep only the first two elements, then grab the first char from both.
  // guard for nameParts is undefined (no match)
  if (!nameParts) {
    return username.slice(0, 2);
  }
  return nameParts
    .slice(0, 2)
    .map((part) => part[0])
    .join("");
};

/**
 * Stops the propagation of an event.
 *
 * @param {Event} event - The event object.
 * @returns {void}
 */
export const stopPropagation = (event) => {
  if (event.stopPropagation) {
    event.stopPropagation(); // W3C model
  } else {
    event.cancelBubble = true; // IE model
  }
};

/**
 * recursive function flattenRecord
 * given input {a: {b: {c: 1}}}
 * should return {a.b.c: 1}
 * @param {object} record
 * @returns {object} flattened record
 */
export const flattenRecord = (record = {}) => {
  // guard
  if (Object.keys(record).length === 0) {
    return record;
  }
  return Object.entries(record).reduce((acc, [key, value]) => {
    if (typeof value === "object") {
      const flattenedObject = flattenRecord(value);
      Object.entries(flattenedObject).forEach(([nestedKey, nestedValue]) => {
        acc[`${key}.${nestedKey}`] = nestedValue;
      });
    } else {
      return {
        ...acc,
        [key]: value,
      };
    }
    return acc;
  }, {});
};

/**
 * Generates the inline edit query parameter based on the isInlineEdit flag.
 * @param {boolean} isInlineEdit - A boolean flag indicating whether inline editing is enabled.
 * @return {string} The generated query parameter string for inline editing. Returns "?inline_edit=true" if isInlineEdit is true, otherwise returns an empty string.
 */
export const getInlineEditQueryParam = (isInlineEdit) => {
  return isInlineEdit ? "?inline_edit=true" : "";
};

/**
 * Updates the given field based on form state and user authorization for cable type.
 *
 * @param {Object} field - The field object to be updated.
 * @param {Object} formState - The current state of the form.
 * @param {Array} authorisation - An array of user roles for authorization.
 *
 * @return {Object} - The updated field object with the calculated disabled state.
 */
export const getUpdatedFields = (field, formState, authorisation) => {
  const installationLock = formState.values?.installation_lock;
  const engineeringLock = formState.values?.engineering_lock;
  const isEngineer = authorisation.some(
    (role) => role.name.toLowerCase() === ENGINEER_ROLE
  );

  const isInstallation = authorisation.some(
    (role) => role.name.toLowerCase() === INSTALLATION_ROLE
  );

  const isAdmin = authorisation.some(
    (role) => role.name.toLowerCase() === ADMIN_ROLE
  );
  if (field.field_name === "installation_lock")
    return {
      ...field,
      disabled: isAdmin
        ? !isAdmin || !engineeringLock
        : !isInstallation || !engineeringLock,
    };
  if (field.field_name === "engineering_lock")
    return {
      ...field,
      disabled: isAdmin ? !isAdmin : !isEngineer || installationLock,
    };

  const enabledWhen = field?.enabledWhen && field?.enabledWhen["cable"];
  if (!enabledWhen) return { ...field };

  const isAvailable = (lock) => {
    return lock.some(
      (condition) =>
        enabledWhen.findIndex(
          (item) => JSON.stringify(item) === JSON.stringify(condition)
        ) > -1
    );
  };

  const enabled = installationLock
    ? isAvailable(CONDITIONS_INSTALLATION) && (isInstallation || isAdmin)
    : engineeringLock
    ? isAvailable(CONDITIONS_DISABLED_FOR_BOTH)
    : isAvailable(CONDITIONS_ENGINEER) && (isEngineer || isAdmin);

  return { ...field, disabled: !enabled };
};

/**
 * This function was created for a very specific reason : renaming cable bundle to bundle.
 * If we use the cable_bundle which is coming from BE, then we can use this function to
 * convert cable_bundle to bundle. Below we have currently 2 cases where we use this function,
 * we can extend it if we need to.
 *
 * @param {string} type - The type of the entity.
 *
 * @return {string} - The name of the entity. If the type is cable_bundle, then it will return bundle.
 */
export const getType = (type) =>
  type === FILTER_TYPES.cable_bundle || /cable-bundles/i.test(type)
    ? "bundle"
    : type;

/**
 * This function handles the value of a field that has a pattern.
 * If the value is empty and the field has a pattern, then it should return null.
 * So that the backend can handle it as an empty value with null.
 * @param {string} value - The value of the field.
 * @param {boolean} hasPattern - A boolean flag indicating whether the field has a pattern.
 * @return {string|null} - The value of the field.
 */
export const processHasPatternInputValue = (value, hasPattern) => {
  return value === "" && hasPattern ? null : value;
};

/*
 * Function to get the sort order string
 * @param {array} sortModel e.g. [{field: "number", sort: "asc"}]
 * @param {array} columns e.g. [{field: "number", sort_name: "number", ...}]
 * @returns {string} sort order string
 */
export const sortModelToString = (sortModel, columns) => {
  if (sortModel && sortModel.length > 0) {
    const sortModelModified = sortModel.map((item) => {
      const column = columns.find((col) => col.field === item.field);
      if (column?.sort_name) {
        return {
          field: column.sort_name ?? column.field,
          sort: item.sort,
        };
      }
      return item;
    });
    return sortModelModified
      .map((item) => `${item.field} ${item.sort}`)
      .join(",");
  } else {
    return "";
  }
};

export const getSelectedMenuItemIndex = (path) => {
  if (!path || typeof path !== "string") return -1;
  const entityName = path.split("/")[1];
  return menuItems.findIndex((item) => item.path.includes(entityName));
};

/**
 * fetchDataWithOptionsAndFilters - Higher order function that takes
 * a fetch function and returns a function that takes options
 * and returns a function that takes a filter string.
 * @param {function} fetchFn - The fetch function to be called.
 * @returns {function} - A function that takes options and returns a function that takes a filter string.
 */
export const fetchDataWithOptionsAndFilters =
  (fetchFn) => (options) => (filterString) => {
    return fetchFn(filterString, { ...options });
  };

/*
 * Function to convert snake case to normal case
 * @param {string} str
 * @returns {string} normal case string
 */
export const snakeCaseToNormalCase = (str) => {
  if (!str) return "";
  return str.split("_").join(" ");
};

/**
 * Function that checks in a given input is a Date object
 * @param {mixed} input
 * @returns {boolean}
 */
export const isDate = (input) => {
  return input instanceof Date;
};

/**
 * Function that converts a Date object to a string
 * value that is accepted by the API (YYYY-MM-DD)
 * @param {Date} date
 * @returns {string}
 */
export const dateToString = (date) => {
  if (!date) return "";
  return moment(date).format("YYYY-MM-DD");
};
