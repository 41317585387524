import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";

import { useFormContext } from "../../hooks/useFormContext";
import { getEntities } from "../../dao/common";
import { ejectDeletedEntities, getIdValues } from "./../../utils/utils";
import useDebounce from "../../hooks/UseDebounce";

const API_FAILURE_ERROR_MESSAGE =
  "The dropdown couldn't be populated. Please try again by refreshing the page or call ICT department.";

function getNameFromValue(options, value, setProjectEntity) {
  if (!value || value === "") return "";
  const project = options?.find((project) => project.id === value);

  if (project === undefined) {
    setProjectEntity(null);
    return "";
  }

  setProjectEntity(project);
  return project?.reference;
}

function setProjectValue(option, value) {
  if (value !== "") {
    return option.id === value;
  }
}

export default function ProjectNumbersLookup({
  className,
  formStateHandlers,
  fieldSetting,
  helperText,
  variant,
  required,
  inputProps,
  ...props
}) {
  const { objectId, setProjectEntity } = useFormContext();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [debouncedInputValue, setDebouncedInputValue] = useDebounce("", 1000);

  useEffect(() => {
    const abortController = new AbortController();
    if (Number(objectId) !== -1 && typeof objectId === "string") {
      setIsLoading(true);
      getEntities(
        "/projects",
        {
          object_ids: objectId,
          order: "reference asc",
          limit: 50,
          ...(debouncedInputValue
            ? {
                filters: JSON.stringify({
                  reference: {
                    type: "search",
                    value: debouncedInputValue,
                  },
                }),
              }
            : {}),
        },
        abortController
      )
        .then((res) => {
          setData(res.response);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    return () => {
      abortController.abort();
    };
  }, [objectId, debouncedInputValue]);

  const { raw } = formStateHandlers;
  const fieldConfig = raw({
    ...fieldSetting,
    onChange: (input) => {
      const value = input.selectValue;
      fieldSetting.onChange({
        target: {
          value,
        },
      });
      return value;
    },
    validate: (value, _values, event) => {
      // Deliberately checking between event.selectValue and value
      // because when using tab to select an item,
      // 'value' might return undefined.
      return !required || !!event.selectValue || !!value;
    },
  });

  const cleanedProjects = ejectDeletedEntities(data);

  const fieldProps = {
    ...fieldConfig,
    ...props,
    onChange: (e, value) => {
      // Re-package value, useFormState does not pass through second arg
      e.selectValue = value;
      return fieldConfig.onChange(e);
    },
    id: `lookup_${fieldConfig.name}`,
    className: clsx(className),
  };

  const finalInputProps = {
    label: props.label,
    error: props.error,
    variant,
    required,
    helperText,
    ...inputProps,
  };

  return (
    <Autocomplete
      {...fieldProps}
      options={getIdValues(cleanedProjects)}
      getOptionLabel={(value) =>
        getNameFromValue(cleanedProjects, value, setProjectEntity)
      }
      isOptionEqualToValue={(option, value) => setProjectValue(option, value)}
      autoSelect={true}
      loading={isLoading}
      loadingText='Loading...'
      renderInput={(params) => (
        <TextField
          {...params}
          {...finalInputProps}
          fullWidth
          label={
            finalInputProps.required
              ? `${finalInputProps.label} (required)`
              : finalInputProps.label
          }
          onChange={(event) => setDebouncedInputValue(event.target.value)}
          error={props.data?.isFieldFailed || error}
          helperText={error ? API_FAILURE_ERROR_MESSAGE : helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress
                    size={20}
                    sx={{ position: "absolute", right: 15 }}
                  />
                ) : (
                  params.InputProps.endAdornment
                )}
              </>
            ),
          }}
          InputLabelProps={{
            // hard coded to true to position label above input
            shrink: true,
            // hard coded to false to prevent an asterisk from showing
            required: false,
          }}
        />
      )}
    />
  );
}
