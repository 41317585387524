import { Link as RouterLink, useLocation } from "react-router-dom";
import { Drawer, List, ListItemButton, ListItemIcon } from "@mui/material";
import { menuItems } from "../constants/drawerMenu";
import { getSelectedMenuItemIndex } from "../utils/utils";

export default function DrawerMenu() {
  const location = useLocation();

  const selectedItem = getSelectedMenuItemIndex(location.pathname);

  return (
    <Drawer
      variant='permanent'
      sx={{
        width: "56px",
        flexShrink: 0,
        whiteSpace: "nowrap",
        zIndex: 100,
        backgroundColor: "grey.light",
      }}
      PaperProps={{
        sx: {
          flexShrink: 0,
          whiteSpace: "nowrap",
          zIndex: 100,
          backgroundColor: "grey.light",
          borderRight: "none",
        },
      }}
    >
      <List
        sx={{
          padding: 1,
          marginTop: "4rem",
          paddingBottom: "2rem",
        }}
      >
        {menuItems.map((item, index) => (
          <ListItemButton
            key={item.title}
            component={RouterLink}
            to={item.path}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
              },
              borderRadius: "4px",
              marginBottom: "0.25rem",
              overflow: "hidden",
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
              "@media (min-height: 900px)": {
                paddingTop: "0.75rem",
                paddingBottom: "0.75rem",
              },
            }}
            selected={selectedItem === index}
            title={item.title}
          >
            <ListItemIcon
              sx={{
                fill: selectedItem === index ? "white" : "black",
                display: "flex",
                minWidth: "auto",
              }}
            >
              {item.icon}
            </ListItemIcon>
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  );
}
