import { useEffect, useState } from "react";
import clsx from "clsx";
import { TextField, Autocomplete } from "@mui/material";

import { getEntities } from "../../dao/common";
import { getIdValues, noop } from "./../../utils/utils";
import { useFormContext } from "../../hooks/useFormContext";

export default function Component({
  className,
  formStateHandlers,
  fieldSetting,
  helperText,
  variant,
  required,
  inputProps,
  route = "",
  setEntity,
  dependencies,
  withDeleted,
  filterFn = noop.fn,
  getOptionLabel = noop.fn,
  isOptionEqualToValue = noop.fn,
  ...props
}) {
  const [data, setData] = useState([]);
  const { objectId, projectEntity } = useFormContext();

  const dependenciesObjectId = dependencies?.objectId ? objectId : null;
  const dependenciesProjectId = dependencies?.projectId
    ? projectEntity?.id
    : null;

  useEffect(() => {
    const abortController = new AbortController();

    // Validate the route
    if (!route || route.length === 0) {
      throw new Error('prop "route" must have a valid value');
    }

    // Check if necessary dependencies are defined and available
    const shouldSkipApiCall =
      (dependencies?.objectId && !dependenciesObjectId) ||
      (dependencies?.projectId && !dependenciesProjectId);

    if (shouldSkipApiCall) {
      return; // Skip API call if required dependencies are undefined to prevent unnecessary API calls
    }

    // Construct query parameters for the API call
    const queryParams = {
      ...(dependenciesObjectId ? { object_ids: dependenciesObjectId } : {}),
      ...(dependenciesProjectId ? { project_ids: dependenciesProjectId } : {}),
      ...(typeof withDeleted !== "undefined" ? { withDeleted } : {}),
    };

    getEntities(route, queryParams, abortController)
      .then((res) => {
        setData(res.response);
      })
      .catch((error) => {
        console.error("Failed to fetch entities:", error);
      });

    // Clean up on unmount
    return () => {
      abortController.abort();
    };
    // Dependencies array:
    // - dependenciesObjectId: Used to trigger the effect when `objectId` changes, but only if `objectId` is required.
    // - dependenciesProjectId: Used to trigger the effect when `projectEntity.id` changes, but only if `projectId` is required.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependenciesObjectId, dependenciesProjectId]);

  const { raw } = formStateHandlers;
  const fieldConfig = raw({
    ...fieldSetting,
    onChange: (input) => {
      const value = input.selectValue;
      fieldSetting.onChange({
        target: {
          value,
        },
      });
      return value;
    },
    validate: (value, _values, event) => {
      // Deliberately checking between event.selectValue and value
      // because when using tab to select an item,
      // 'value' might return undefined.
      return !required || !!event.selectValue || !!value;
    },
    compare: () => false,
  });
  const filteredItems = data.filter(filterFn);

  useEffect(() => {
    if (setEntity) {
      const item = filteredItems.find((item) => item.id === fieldConfig.value);
      setEntity(item);
    }
  }, [fieldConfig.value, filteredItems]);

  const fieldProps = {
    ...fieldConfig,
    ...props,
    onChange: (e, value) => {
      // Re-package value, useFormState does not pass through second arg
      e.selectValue = value;
      return fieldConfig.onChange(e);
    },
    id: `lookup_${fieldConfig.name}`,
    className: clsx(className),
  };

  return (
    <Autocomplete
      {...fieldProps}
      options={getIdValues(filteredItems)}
      getOptionLabel={getOptionLabel(filteredItems)}
      isOptionEqualToValue={isOptionEqualToValue}
      autoSelect={true}
      renderInput={(params) => (
        <TextField
          label={props.label}
          error={props.error}
          variant={variant}
          required={required}
          helperText={helperText}
          {...params}
          {...inputProps}
          fullWidth
        />
      )}
    />
  );
}
