import { TextField } from "@mui/material";
import InputFieldType from "../../enums/InputFieldType";
import BooleanField from "./BooleanField";
import NumberField from "./NumberField";
import ObjectTypeLookup from "./ObjectTypeLookup";
import SignalTypeLookup from "./SignalTypeLookup";
import ProjectTypeLookup from "./ProjectTypeLookup";
import EquipmentTypeLookup from "./EquipmentTypeLookup";
import LookupField from "./LookupField";
import ObjectsLookup from "./ObjectsLookUp";
import ObjectNumbersLookup from "./ObjectNumbersLookUp";
import SystemGroupNumbersLookup from "./SystemGroupNumbersLookUp";
import SystemNumbersLookup from "./SystemNumbersLookUp";
import SectionNumbersLookup from "./SectionNumbersLookUp";
import EquipmentNumbersLookup from "./EquipmentNumbersLookUp";
import LocationNumbersLookup from "./LocationNumbersLookUp";
import ProjectNumbersLookup from "./ProjectNumbersLookup";
import Lookup from "./Lookup";
import { withConfig } from "../utils";
import { processHasPatternInputValue } from "../../utils/utils";

/**
 * get the field type
 * @param {string} type
 * @param {function} setCableBundleEntity
 * @returns {object} the field type
 */

export const getFieldTypes = (type, setCableBundleEntity) => {
  if (!type) return null;

  const fieldTypes = {
    email: function FormFactoryEmail({
      formStateHandlers,
      fieldSetting,
      inputProps,
      ...props
    }) {
      const { text } = formStateHandlers;
      const fieldConfig = text(fieldSetting);
      return (
        <TextField
          {...{
            ...fieldConfig,
            value: fieldConfig.value.username || fieldConfig.value,
          }}
          {...props}
          inputProps={inputProps}
          label={props.required ? `${props.label} (required)` : props.label}
          error={props.data?.isFieldFailed}
          InputLabelProps={{
            // hard coded to true to position label above input
            shrink: true,
            // hard coded to false to prevent an asterisk from showing
            required: false,
          }}
        />
      );
    },
    date: function FormFactoryDate({
      formStateHandlers,
      fieldSetting,
      inputProps,
      ...props
    }) {
      const { text } = formStateHandlers;
      const fieldConfig = text(fieldSetting);
      return (
        <TextField
          {...fieldConfig}
          {...props}
          type='date'
          inputProps={inputProps}
          label={props.required ? `${props.label} (required)` : props.label}
          error={props.data?.isFieldFailed}
          InputLabelProps={{
            // hard coded to true to position label above input
            shrink: true,
            // hard coded to false to prevent an asterisk from showing
            required: false,
          }}
        />
      );
    },
    default: function FormFactoryDefault({
      formStateHandlers,
      fieldSetting,
      inputProps,
      ...props
    }) {
      const { text, raw } = formStateHandlers;
      const defaultFieldConfig = text(fieldSetting);
      const fieldConfig = raw({
        ...defaultFieldConfig,
        onChange: (input) => {
          const value = processHasPatternInputValue(
            input.target.value,
            props?.data?.has_pattern
          );
          fieldSetting.onChange({
            target: {
              fieldname: fieldSetting.name,
              value,
            },
          });
          return value;
        },
      });
      return (
        <TextField
          {...fieldConfig}
          {...props}
          inputProps={inputProps}
          label={
            fieldConfig?.required || props?.data?.required
              ? `${props.label} (required)`
              : props.label
          }
          error={props.data?.isFieldFailed}
          InputLabelProps={{
            // hard coded to true to position label above input
            shrink: true,
            // hard coded to false to prevent an asterisk from showing
            required: false,
          }}
        />
      );
    },
    [InputFieldType.BOOLEAN]: BooleanField,
    [InputFieldType.NUMBER]: NumberField,
    [InputFieldType.INTEGER]: NumberField,
    [InputFieldType.OBJECT_TYPES]: ObjectTypeLookup,
    [InputFieldType.SIGNAL_TYPES]: SignalTypeLookup,
    [InputFieldType.PROJECT_TYPES]: ProjectTypeLookup,
    [InputFieldType.EQUIPMENT_TYPES]: EquipmentTypeLookup,
    [InputFieldType.LOOKUP]: LookupField,
    [InputFieldType.OBJECTS]: ObjectsLookup,
    [InputFieldType.OBJECT_NUMBERS]: ObjectNumbersLookup,
    [InputFieldType.SYSTEM_GROUP_NUMBER_LIST]: SystemGroupNumbersLookup,
    [InputFieldType.SYSTEM_NUMBER_LIST]: SystemNumbersLookup,
    [InputFieldType.SECTIONS_LIST]: SectionNumbersLookup,
    [InputFieldType.EQUIPMENT_LIST]: EquipmentNumbersLookup,
    [InputFieldType.LOCATION_LIST]: LocationNumbersLookup,
    [InputFieldType.PROJECTS_LIST]: ProjectNumbersLookup,
    [InputFieldType.CABLE_TYPE_LIST]: withConfig({
      route: "/cable-types",
      filterFn: (item) => {
        return item.type.length > 0;
      },
      getOptionLabel:
        (items = []) =>
        (value) => {
          if (!value || value === "") return "";
          const matchedItem = items.find((o) => o.id === value);

          return matchedItem?.type ?? "";
        },
      isOptionEqualToValue: (option, value) => {
        return option.id === value;
      },
      withDeleted: false,
    })(Lookup),
    [InputFieldType.CABLE_BUNDLE_LIST]: withConfig({
      route: `/cable-bundles`,
      filterFn: (item) => {
        return typeof item?.number === "number";
      },
      getOptionLabel:
        (items = []) =>
        (value) => {
          const matchedItem = items.find((o) => o.id === value);
          return matchedItem?.number ?? "";
        },
      setEntity: setCableBundleEntity,
      dependencies: { objectId: true },
    })(Lookup),
    [InputFieldType.DRUM_LIST]: withConfig({
      route: `/drums`,
      filterFn: (item) => {
        return item?.number;
      },
      getOptionLabel:
        (items = []) =>
        (value) => {
          const matchedItem = items.find((o) => o.id === value);
          return matchedItem?.number ?? "";
        },
      isOptionEqualToValue: (option, value) => {
        return option.id === value;
      },
      dependencies: { objectId: true, projectId: true },
      withDeleted: false,
    })(Lookup),
    [InputFieldType.ORDER_LIST]: withConfig({
      route: `/orders`,
      filterFn: (item) => {
        return item?.number;
      },
      getOptionLabel:
        (items = []) =>
        (value) => {
          const matchedItem = items.find((o) => o.id === value);
          return matchedItem?.number ?? "";
        },
      isOptionEqualToValue: (option, value) => {
        return option.id === value;
      },
      dependencies: { objectId: true, projectId: true },
      withDeleted: false,
    })(Lookup),
  };

  return fieldTypes[type];
};
