import React, { useEffect, useState } from "react";
import FormFieldFactory from "./FormFieldFactory";
import { alpha } from "@mui/material/styles";
import fieldSize from "../styles/FieldSize";
import { Typography, Grid, IconButton, TextField } from "@mui/material";
import { getEmptyRequiredFields, isVisible } from "./FormFieldParser";
import ButtonWithSpinner from "../components/ButtonWithSpinner";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import logLevel from "loglevel";
import EditModes from "./../enums/EditModes";
import { Box } from "@mui/system";
import { FormGroup } from "./FormGroup";

const log = logLevel.getLogger("verbose");

export const REDRAW_DEBOUNCE_TIMEOUT = 250;

export function getVisibleFields({
  group,
  formState,
  values,
  commonFieldProps,
  copyOneValue,
  entityType,
  UserPermissionsContext,
}) {
  const compareHeader = (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Typography variant='overline'>Field</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography variant='overline'>Before</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='overline'>After</Typography>
      </Grid>
    </Grid>
  );
  let shownCompareLabel = false;

  const editMode =
    typeof values.number === "undefined" ? EditModes.CREATE : EditModes.UPDATE;

  return group
    .filter((data) => isVisible(data, formState.values, entityType))
    .map((data) => {
      const isComparable = !!data.compare_to;
      const linkedTodata = group.filter(
        (linkeddata) => linkeddata.linked_to === data.id
      );
      const field = (
        <FormFieldFactory
          key={data.field_name}
          data={data}
          linkdata={linkedTodata}
          {...commonFieldProps}
          required={data.required}
          editMode={editMode}
          useUserPermissionsContext={UserPermissionsContext}
          entity={entityType}
        />
      );
      if (data.linked_to) {
        return null;
      }
      if (typeof data.compareField !== "undefined") {
        return (
          <div className='compareContainer' key={"comparecontainer_" + data.id}>
            {!shownCompareLabel && (shownCompareLabel = true) && compareHeader}
            <Grid container spacing={3}>
              <Grid item xs={4} className='compareLabel'>
                {data.name}
              </Grid>
              <Grid item xs={3} className='comparePrevious'>
                <TextField
                  disabled={isComparable}
                  variant='outlined'
                  name={data.compareField.fieldname}
                  value={values[data.compareField.fieldname]}
                />
                {isComparable && (
                  <IconButton
                    edge='end'
                    aria-label='copy value'
                    onClick={() => copyOneValue(data)}
                  >
                    <FileCopyIcon />
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={3} className='comparedValue'>
                {field}
              </Grid>
            </Grid>
          </div>
        );
      }
      shownCompareLabel = false;
      return field;
    });
}

function FormFactory({
  fields,
  sortFields,
  setKnownValues,
  getDefaultFields,
  isVisible,
  values,
  formState,
  formStateHandlers,
  onDebounce,
  setDefaultFields,
  setVisibleFields,
  sortingFilter,
  copyAll,
  copyAllInProgress,
  copyOneValue,
  entityType,
  setEmptyRequiredFields,
  ...props
}) {
  const [displayCopyButton, setDisplayCopyButton] = useState(false);

  const sortedFields = React.useMemo(
    () => sortFields(fields, values, sortingFilter),
    [fields, sortFields, sortingFilter, values]
  );
  const actualFields = React.useMemo(
    () =>
      sortedFields.reduce((acc, group) => {
        return [].concat(acc, group);
      }, []),
    [sortedFields]
  );
  const [fieldsState, setFieldsState] = useState([]);

  useEffect(() => {
    const defaultFields = getDefaultFields(formState, visibleFields);
    setDefaultFields(defaultFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualFields]);

  useEffect(() => {
    setFieldsState(sortedFields);
    // Although formState is used
    // we only need to rerun this once-off on a change to values.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, sortedFields]);

  useEffect(() => {
    setKnownValues(formState, sortedFields, values);
  }, [values]);

  const visibleFields = actualFields.filter((field) =>
    isVisible(field, formState.values, entityType)
  );

  // Debounce the full-form validation
  useEffect(() => {
    const handler = setTimeout(() => {
      const emptyRequiredFields = getEmptyRequiredFields(
        formState,
        actualFields
      );

      setEmptyRequiredFields(emptyRequiredFields);

      setVisibleFields(
        visibleFields,
        getDefaultFields(formState, visibleFields)
      );
    }, 350);
    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualFields, formState.values, formState.validity]);

  useEffect(() => {
    const hasComparableFields = visibleFields.some((field) => field.compare_to);

    if (hasComparableFields) {
      setDisplayCopyButton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, actualFields]);

  function deferredDebounce(e) {
    if (onDebounce) {
      const { name, value } = e.target;
      const validity = formState.validity[name];
      return onDebounce(name, value, validity);
    }
  }
  const commonFieldProps = {
    formStateHandlers,
    formState,
    values,
    onDebounce: deferredDebounce,
    ...props,
  };

  const formGroupProps = {
    formState,
    values,
    commonFieldProps,
    copyOneValue,
    entityType,
  };

  return (
    <Box
      component='form'
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginRight: "-gap",
        paddingRight: "16px", // Create space for info-icon
        "& > *": fieldSize(),
        "& > .compareContainer": {
          justifyContent: "space-between",
          width: "100%",
          "& .comparePrevious, & .compareLabel": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignSelf: "center",
          },
          "& .comparePrevious": {
            color: "rgba(0, 0, 0, 0.35)",
            "& .Mui-disabled": {
              backgroundColor: "readOnly.color",
              color: "readOnly.color",
            },
          },
          "& .comparedValue": {
            "& .MuiInputBase-input": {
              color: "editableValue.color",
            },
            "& .MuiInputLabel-shrink": {
              color: "editableValue.color",
            },
          },
          "& .MuiTextField-root": {
            width: "100%",
          },
        },
        "& > .fieldSize--s": fieldSize("s"),
        "& > .fieldSize--m": fieldSize("m"),
        "& > .fieldSize--l": fieldSize("l"),
        "& > .fieldSize--xl": fieldSize("xl"),
        "& > .groupHeader": {
          color: alpha("#1A2027", 0.35),
          flex: "calc(100% - 4px)",
          fontSize: "1rem",
          marginTop: 4 * 0.5,
        },
        "& .MuiInputAdornment-root": {
          marginRight: "-10px",
        },
        "& .MuiButtonBase-root, .MuiIconButton-root": {
          padding: "0px",
          textAlign: "right",
        },
        "& .MuiDivider-vertical": {
          height: "55px",
          marginRight: "5px",
        },
        "& .MuiIconButton-label": {
          fontSize: "1rem",
        },
        "& .linkedField": {
          "& .MuiFormLabel-root": {
            maxWidth: "180px",
            wordWrap: "normal",
          },
        },
      }}
    >
      {fieldsState.map((group, groupIndex) => (
        <FormGroup {...{ group, ...formGroupProps }} key={groupIndex} />
      ))}
      {displayCopyButton && (
        <Grid container style={{ width: "100%" }} spacing={3}>
          <Grid item xs={9}></Grid>

          <Grid item xs={3}>
            <ButtonWithSpinner
              loading={copyAllInProgress}
              sx={{
                width: "-webkit-fill-available",
              }}
              variant='contained'
              color='secondary'
              startIcon={<FileCopyIcon />}
              onClick={() => copyAll(visibleFields)}
            >
              Copy all Values
            </ButtonWithSpinner>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default FormFactory;
