import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import useDebounce from "../hooks/UseDebounce";

import InputFieldType from "../enums/InputFieldType";
import logLevel from "loglevel";
import FieldWrapper from "./FieldWrapper";

import { useFormContext } from "../hooks/useFormContext";
import EditModes from "./../enums/EditModes";
import FieldName from "./../enums/FieldName";
import UserRights from "../enums/UserRights";
import { createChangeHandler } from "./utils";
import { getFieldTypes } from "./field/getFieldTypes";

const log = logLevel.getLogger("verbose");

/**
 *
 * @param {object} arg React props
 * @param {import('../dao/fields').Field} arg.data
 * @returns
 */
function FormFieldFactory({
  className,
  data,
  linkdata,
  onChange,
  onDebounce, // deferredDebounce
  required,
  formState,
  values,

  editMode,
  useUserPermissionsContext,
  entity,
  ...props
}) {
  const [debouncedValue, setDebouncedValue] = useDebounce(undefined, 1000);
  const [errorMessage, setErrorMessage] = useState(null);
  log.trace(data.field_name, formState.values[data.field_name]);

  const { formErrors, setCableBundleEntity } = useFormContext();

  const { isAllowedTo, isLoading } = useUserPermissionsContext();

  const {
    field_name: fieldname,
    label: fancyName,
    type: datatype,
    values: lookupvalues,
    fieldsize = "m",
    min_length: minLength,
    max_length: maxLength,
    // description,
    disabled,
  } = data;

  useEffect(() => {
    log.trace(fieldname, fancyName, datatype);
    // On initial render debouncedValue equals undefined and should be skipped
    if (onDebounce && debouncedValue !== undefined) {
      onDebounce({
        // calling deferred debounce with fake event object
        target: {
          name: debouncedValue.fieldname || fieldname,
          value: debouncedValue.value,
        },
      })
        .then((result) => {
          setErrorMessage(null);
          return result;
        })
        .catch((e) => {
          log.error(e);
          setErrorMessage(e.response?.body.message ?? "Unknown Error Ocurred");
        });
    }
    // REASON: Linting incorrectly forces watch on `fieldname` and `onDebounce`
  }, [debouncedValue]); // eslint-disable-line  react-hooks/exhaustive-deps

  useEffect(() => {
    if (typeof debouncedValue === "undefined" && values.object_id) {
      setDebouncedValue({
        value: values.object_id,
        fieldname: FieldName.OBJECT_ID,
      });
    }
  }, [values.object_id]);

  const fieldSetting = {
    name: fieldname,
  };
  fieldSetting.onChange = createChangeHandler(setDebouncedValue, onChange);

  const linkedfieldSetting = {
    name: linkdata.length > 0 ? linkdata[0].fieldname : null,
    currentValue:
      linkdata.length > 0 ? formState.values[linkdata[0].fieldname] : null,
    linkActive: linkdata.length,
  };
  linkedfieldSetting.onChange = createChangeHandler(
    setDebouncedValue,
    onChange
  );

  const defaultFormProps = {
    inputProps: {
      autoComplete: "off",
    },
    // Construct className field, passing through external classes as well
    // as potentially setting conditional states as classes.
    className: clsx(
      className,
      `fieldSize--${fieldsize}`,
      !!(errorMessage || formState.errors[fieldname]) && "errorField",
      !!linkedfieldSetting.linkActive && "linkedField"
    ),
    label: fancyName,
    variant: "outlined",
    helperText: formErrors?.[fieldname], // || description,
    error: !!formErrors?.[fieldname],
    required,
    disabled,
  };

  if (required !== undefined) {
    defaultFormProps.required = required;
  }

  const isAllowedToUpdate = isAllowedTo(UserRights.UPDATE, entity);

  // Disable the fields if user is not allowed to create or update.
  if (
    editMode === EditModes.UPDATE &&
    !isLoading &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    !isAllowedToUpdate
  ) {
    defaultFormProps.disabled = true;
  }

  // TODO: When uncommenting the following, the form will stay invalid when min
  // or max length is defined, but an error will never be shown. So we have to
  // add functionality to show this error message
  // if (minLength !== null) {
  //   if (datatype === 'integer') {
  //     defaultFormProps.inputProps.min = '1'.padEnd(minLength, '0');
  //   } else {
  //     defaultFormProps.inputProps.minLength = Number(minLength);
  //   }
  // }
  // if (maxLength !== null) {
  //   if (datatype === 'integer') {
  //     defaultFormProps.inputProps.max = '9'.padEnd(maxLength, '9');
  //   } else {
  //     defaultFormProps.inputProps.maxLength = Number(maxLength);
  //   }
  // }

  let actualDataType = "";
  if (datatype === "string" || datatype === "number") {
    actualDataType = lookupvalues ? InputFieldType.LOOKUP : datatype;
  } else {
    actualDataType = datatype;
  }
  const FactoryComponent = useMemo(() => {
    // For cable bundle list we need to pass the setCableBundleEntity function
    if (actualDataType === InputFieldType.CABLE_BUNDLE_LIST) {
      return getFieldTypes(actualDataType, setCableBundleEntity);
    }
    return (
      getFieldTypes(actualDataType) || getFieldTypes(InputFieldType.DEFAULT)
    );
  }, [actualDataType, setCableBundleEntity]);

  const fieldComponentProps = {
    values,
    data,
    fieldSetting,
    ...props,
    ...defaultFormProps,
  };

  if (linkedfieldSetting.linkActive) {
    fieldComponentProps.linkdata = linkdata;
    fieldComponentProps.linkedfieldSetting = linkedfieldSetting;
  }

  return (
    <FieldWrapper
      {...{
        data,
      }}
    >
      <FactoryComponent {...fieldComponentProps} />
    </FieldWrapper>
  );
}

export default FormFieldFactory;
